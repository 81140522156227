import React, { FC, useEffect, useRef, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Box,
  Modal,
  Backdrop,
  Fade,
  Typography,
  Divider,
} from "@mui/material";
import {
  useCreateEmployeeMutation,
  useEditEmployeeMutation,
  useGetEmployeeByIdQuery,
  useGetUserRolesQuery,
  setError,
  useGetSitesByIdQuery,
} from "../../../../redux-services";
import { useDispatch, useSelector } from "react-redux";
import Error from "../../../../components/ErrorBox/Error";
import { MuiTelInput, MuiTelInputInfo } from "mui-tel-input";
import DescriptionIcon from "@mui/icons-material/Description";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { RootState } from "../../../../store/store";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export interface UserState {
  firstname: string;
  lastname: string;
  site_id: any;
  role_id: string;
  ccode: string;
  iute164_phone: string;
  contact_no: string;
  job_title: string;
  profile_img: any;
  profile_img_url: any;
  delete_img: any;
  aadhar_card: any;
  aadhar_card_url: string;
  aadhar_card_type: string;
  pan_card: any;
  pan_card_url: string;
  pan_card_type: string;
  bank_detail: any;
  bank_detail_url: string;
  bank_detail_type: string;
  driving_license: any;
  driving_license_url: string;
  driving_license_type: string;
  isSiteChange: boolean;
  salary: any;
  pan_card_number: string;
  aadhar_card_number: string;
}
interface FormErrors {
  firstname?: string;
  lastname?: string;
  contact_no?: string;
  job_title?: string;
  role_id?: string;
  aadhar_card_number?: string;
  pan_card_number?: string;
}

const EmployeeAddEditModal: FC<{
  open: boolean;
  handleModel: any;
  siteInfo: { id: string; name: string };
  id: string;
}> = ({ open, handleModel, siteInfo, id }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    borderRadius: 2,
    p: 2,
  };
  const formRef = useRef<HTMLFormElement>(null);

  const intialState = {
    firstname: "",
    lastname: "",
    site_id: siteInfo,
    role_id: "",
    ccode: "+91",
    iute164_phone: "",
    contact_no: "",
    job_title: "",
    profile_img: null,
    profile_img_url: "",
    delete_img: [],
    aadhar_card: null,
    aadhar_card_url: "",
    aadhar_card_type: "",
    pan_card: null,
    pan_card_url: "",
    pan_card_type: "",
    bank_detail: null,
    bank_detail_url: "",
    bank_detail_type: "",
    driving_license: null,
    driving_license_url: "",
    driving_license_type: "",
    isSiteChange: false,
    salary: "",
    pan_card_number: "",
    aadhar_card_number: "",
  };
  let dispatch = useDispatch();
  const [formData, setFormData] = useState<UserState>(intialState);
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [createEmployee, result] = useCreateEmployeeMutation();
  const [editEmployee, editResult] = useEditEmployeeMutation();
  const UserRolesdata = useGetUserRolesQuery({
    orgId: localStorage.getItem("orgId") ?? "",
  });

  const siteOrgId = useSelector((state: RootState) => state.auth.siteOrgId);

  const { data: SitesData } = useGetSitesByIdQuery(siteInfo.id, {
    skip: !siteInfo.id,
  });
  const organizationName =
    SitesData?.response.organization_id?.[0]?.organization_name || "";
  const { data: UserData } = useGetEmployeeByIdQuery(id, {
    skip: !id,
  });

  useEffect(() => {
    if (UserData?.status === 200) {
      let data = UserData?.response;
      const imageFileTypes = [
        "png",
        "jpg",
        "jpeg",
        "gif",
        "bmp",
        "webp",
        "tiff",
        "svg",
        "heic",
        "heif",
        "jfif",
      ];
      const wordFileTypes = ["doc", "docx", "dot", "dotx"];
      const pdfFileTypes = ["pdf"];
      let fileArray = [
        { aadhar: data?.aadhar_card?.path ?? null },
        { pan: data?.pan_card?.path ?? null },
        { bank: data?.bank_detail?.path ?? null },
        { driving: data?.driving_license?.path ?? null },
      ];
      let aadhar_type,
        pan_type,
        bank_type,
        driving_type = "";
      for (let obj of fileArray) {
        let fileName = Object.keys(obj)[0];
        if (Object.values(obj)[0] !== null) {
          let fileType = Object.values(obj)[0]?.split(".").pop();
          if (imageFileTypes.includes(fileType)) {
            if (fileName === "aadhar") {
              aadhar_type = "image";
            }
            if (fileName === "pan") {
              pan_type = "image";
            }
            if (fileName === "bank") {
              bank_type = "image";
            }
            if (fileName === "driving") {
              driving_type = "image";
            }
          }
          if (wordFileTypes.includes(fileType)) {
            if (fileName === "aadhar") {
              aadhar_type = "word";
            }
            if (fileName === "pan") {
              pan_type = "word";
            }
            if (fileName === "bank") {
              bank_type = "word";
            }
            if (fileName === "driving") {
              driving_type = "word";
            }
          }
          if (pdfFileTypes.includes(fileType)) {
            if (fileName === "aadhar") {
              aadhar_type = "pdf";
            }
            if (fileName === "pan") {
              pan_type = "pdf";
            }
            if (fileName === "bank") {
              bank_type = "pdf";
            }
            if (fileName === "driving") {
              driving_type = "pdf";
            }
          }
        }
      }
      setFormData({
        ...formData,
        firstname: data?.firstname ?? "",
        lastname: data?.lastname ?? "",
        role_id: String(data?.role_id),
        ccode: data?.ccode ?? "",
        iute164_phone: data?.ccode + data?.contact_no,
        contact_no: data?.contact_no ?? "",
        job_title: data?.job_title ?? "",
        profile_img: data?.profile_img?.path,
        profile_img_url: data?.profile_img_url,
        aadhar_card: data?.aadhar_card?.path ?? null,
        aadhar_card_url: data?.aadhar_card_url ?? "",
        aadhar_card_type: aadhar_type ?? "",
        pan_card: data?.pan_card?.path ?? null,
        pan_card_url: data?.pan_card_url ?? "",
        pan_card_type: pan_type ?? "",
        bank_detail: data?.bank_detail?.path ?? null,
        bank_detail_url: data?.bank_detail_url ?? "",
        bank_detail_type: bank_type ?? "",
        driving_license: data?.driving_license?.path ?? null,
        driving_license_url: data?.driving_license_url ?? "",
        driving_license_type: driving_type ?? "",
        salary: data?.salary ?? "",
      });
    }
    return () => {
      dispatch(setError([]));
    };
  }, [UserData]);

  if (result.isSuccess && result.data.status === 200) {
    handleModel(false);
  }

  if (editResult.isSuccess && editResult.data.status === 200) {
    handleModel(false);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const checkError = validate(formData);
    const firstName = formData?.firstname?.trim() ?? "";
    const lastName = formData?.lastname?.trim() ?? "";
    const fullName = `${firstName} ${lastName}`;

    if (Object.values(checkError)?.length > 0) {
      setFormErrors(checkError);
    } else {
      setFormErrors({});
      dispatch(setError([]));
      let data = new FormData();
      data.append("profile_img", formData.profile_img);
      data.append(
        "aadhar_card",
        formData.aadhar_card === null ? undefined : formData.aadhar_card
      );
      data.append(
        "pan_card",
        formData.pan_card === null ? undefined : formData.pan_card
      );
      data.append(
        "bank_detail",
        formData.bank_detail === null ? undefined : formData.bank_detail
      );
      data.append(
        "driving_license",
        formData.driving_license === null ? undefined : formData.driving_license
      );
      if (id) {
        data.append("delete_img", JSON.stringify(formData.delete_img));
      }
      data.append("firstname", formData.firstname.trim());
      data.append("lastname", formData.lastname.trim());
      data.append("username", fullName);
      data.append("role_id", formData.role_id);
      data.append("salary", formData.salary);
      data.append("job_title", formData.job_title.trim() ?? "");
      data.append("ccode", formData.ccode);
      data.append("contact_no", formData.contact_no.trim());
      data.append(
        "site_id",
        formData.site_id?.id !== "" ? formData.site_id?.id : null
      );
      data.append("isSiteChange", String(formData.isSiteChange));
      data.append("organization_name", organizationName);
      data.append("organization_id", siteOrgId === "" ? "null" : siteOrgId);
      data.append("aadhar_card_number", formData.aadhar_card_number);
      data.append("pan_card_number", formData.pan_card_number);
      data.append("id", id || (null as any));
      id ? editEmployee(data) : createEmployee(data);
    }
  };

  const handleExternalSubmit = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.trimStart(),
    }));

    const errors = validate({ ...formData, [name]: value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors[name as keyof UserState],
    }));
  };


  const validate = (formData: UserState): Partial<UserState> => {
    const errors: Partial<UserState> = {};
    const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?0-9]+/;
  
    const validateField = (field: keyof UserState, requiredMessage: string) => {
      const value = formData[field]?.trim();
      if (!value) {
        errors[field] = requiredMessage;
      } else if (regex.test(value)) {
        errors[field] = `${field} cannot contain special characters!`;
      }
    };
  
    validateField('firstname', "First Name is required!");
    validateField('lastname', "Last Name is required!");
    validateField('job_title', "Job Title is required!");
  
    const contactNo = formData.contact_no?.trim();
    if (!contactNo) {
      errors.contact_no = "Contact number is required!";
    } else if (contactNo.length !== 10 || !/^\d{10}$/.test(contactNo)) {
      errors.contact_no = "Contact number must be exactly 10 digits long!";
    } else if (!/^([6789]\d{9})$/.test(contactNo)) {
      errors.contact_no = "For Indian numbers, the contact number must start with digits 6, 7, 8, or 9!";
    }
  
    if (formData.role_id === "") {
      errors.role_id = "Employee Role is required!";
    }
  
    if (formData.site_id === "") {
      errors.site_id = "Site is required!";
    }
  
    const aadhar = formData.aadhar_card_number?.trim();
    if (aadhar && (aadhar.length !== 12 || !/^\d{12}$/.test(aadhar))) {
      errors.aadhar_card_number = "Aadhar card number must be exactly 12 digits long!";
    }
  
    const pan = formData.pan_card_number?.trim();
    if (pan && !/^[A-Z]{5}\d{4}[A-Z]$/.test(pan)) {
      errors.pan_card_number = "PAN card number must be in the format ABCDE1234F!";
    }
  
    return errors;
  };
  

  const selectChange = (event: SelectChangeEvent) => {
    setFormData({ ...formData, role_id: event.target.value });
    const errors = validate({ ...formData, ["role_id"]: event.target.value });
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      ["role_id"]: errors["role_id" as keyof UserState],
    }));
  };

  const onPhoneChange = (value: string, parsedNumber: MuiTelInputInfo) => {
    let contactNo = parsedNumber?.nationalNumber ?? "";
    let iute164Phone = parsedNumber?.numberValue ?? "";
    let ccode = "+" + (parsedNumber?.countryCallingCode ?? "");

    const updatedFormData = {
      ...formData,
      contact_no: contactNo,
      iute164_phone: iute164Phone,
      ccode: ccode,
    };

    const contactNoErrors = validate({
      contact_no: contactNo,
      firstname: "",
      lastname: "",
      site_id: undefined,
      role_id: "",
      salary: "",
      ccode: "",
      iute164_phone: "",
      job_title: "",
      profile_img: undefined,
      profile_img_url: undefined,
      delete_img: [],
      aadhar_card: undefined,
      aadhar_card_url: "",
      pan_card: undefined,
      pan_card_url: "",
      bank_detail: undefined,
      bank_detail_url: "",
      driving_license: undefined,
      driving_license_url: "",
      aadhar_card_type: "",
      bank_detail_type: "",
      driving_license_type: "",
      pan_card_type: "",
      isSiteChange: false,
      aadhar_card_number: "",
      pan_card_number: "",
    });

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      contact_no: contactNoErrors.contact_no,
    }));

    setFormData(updatedFormData);
  };

  const handleChangeImage = (event: any) => {
    let file = event.target.files[0];
    let setFileUrl = URL.createObjectURL(file);
    setFormData({
      ...formData,
      profile_img: file,
      profile_img_url: setFileUrl,
    });
    const thumbnailElement = document.getElementById(
      "images"
    ) as HTMLInputElement | null;

    if (thumbnailElement) {
      thumbnailElement.value = "";
    }
  };
  const handleDocumentChange = (event: any, id: string) => {
    let file = event.target.files[0];
    const fileTypes = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/webp",
      "image/tiff",
      "image/svg+xml",
      "image/heic",
      "image/heif",

      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

      "application/pdf",
    ];

    const wordFileTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    const pdfFileTypes = ["application/pdf"];
    let fileType = "image";

    if (pdfFileTypes.includes(file.type)) {
      fileType = "pdf";
    }
    if (wordFileTypes.includes(file.type)) {
      fileType = "word";
    }

    if (fileTypes.includes(file.type)) {
      let nameUrl = event.target.name + "_url";
      let file_type = event.target.name + "_type";
      const url = URL.createObjectURL(file);
      setFormData({
        ...formData,
        [event.target.name]: file,
        [nameUrl]: url,
        [file_type]: fileType,
      });
    } else {
      if (document.getElementById(id)) {
        let field: any = document.getElementById(id);
        field.value = "";
      }
      alert("This file type is not allowed.");
      setFormData({
        ...formData,
        [event.target.name]: "",
      });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="form-outers karuModal">
          <Typography
            id="transition-modal-title"
            variant="h5"
            className="inner-headings"
            component="h2"
          >
            {id ? "Edit Employee" : "Add Employee"}
          </Typography>

          <HighlightOffIcon
            className="closeicon"
            onClick={() => handleModel(false)}
          />

          <Box className="modalBody">
            <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
            <form ref={formRef} onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="firstname"
                    label="First Name *"
                    name="firstname"
                    type="text"
                    size="small"
                    autoComplete="firstname"
                    value={formData.firstname}
                    onChange={handleChange}
                    error={!!formErrors.firstname}
                    helperText={formErrors.firstname}
                  />
                  <Error current_key="firstname" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="lastname"
                    label="Last Name *"
                    name="lastname"
                    type="text"
                    size="small"
                    autoComplete="lastname"
                    value={formData.lastname}
                    onChange={handleChange}
                    error={!!formErrors.lastname}
                    helperText={formErrors.lastname}
                  />
                  <Error current_key="lastname" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MuiTelInput
                    value={formData?.iute164_phone}
                    onChange={onPhoneChange}
                    defaultCountry={"IN"}
                    size="small"
                    fullWidth={true}
                    required={true}
                    inputProps={{maxLength:15}}
                    error={!!formErrors.contact_no}
                    helperText={formErrors.contact_no}
                  />
                  <Error current_key="contact_no" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="job_title"
                    label="Job Title *"
                    name="job_title"
                    type="job_title"
                    size="small"
                    autoComplete="job_title"
                    value={formData.job_title}
                    onChange={handleChange}
                    error={!!formErrors.job_title}
                    helperText={formErrors.job_title}
                  />
                  <Error current_key="job_title" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        User Roles *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.role_id}
                        label="User Roles *"
                        onChange={selectChange}
                        error={!!formErrors.role_id}
                      >
                        {UserRolesdata?.data?.response.map(
                            (item: any, index: number) => (
                              <MenuItem
                                key={index}
                                value={item?._id}
                                selected={
                                  item?._id === formData?.role_id 
                                }
                              >
                                {item?.role_name}
                              </MenuItem>
                            )
                          )}
                      </Select>
                      {formErrors.role_id && (
                        <Typography variant="body1" className="error-alert-msg">
                          {formErrors.role_id}
                        </Typography>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Sites Names *
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData.site_id}
                        label="Sites Name"
                        renderValue={() => formData.site_id?.name}
                      >
                        <MenuItem value={siteInfo?.id}>
                          {siteInfo?.name}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="salary"
                    label="Salary"
                    name="salary"
                    size="small"
                    type="number"
                    autoComplete="salary"
                    value={formData.salary}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="images"
                      name="images"
                      label="Profile Images"
                      className="accessory_img"
                      type="file"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      inputProps={{ accept: "image/*" }}
                      autoComplete="images"
                      onChange={handleChangeImage}
                    />
                    <Error current_key="images" />
                  </Grid>
                  {formData.profile_img_url !== "" && (
                    <Grid item xs={12} className="imageDiv">
                      <div>
                        <img
                          src={formData.profile_img_url}
                          alt={`profile-img`}
                          className="preview-img"
                        />
                      </div>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    id="transition-modal-title"
                    variant="h5"
                    className="inner-headings"
                    component="h2"
                  >
                    {"Document Section"}
                  </Typography>
                  <Divider sx={{ marginTop: 2, marginBottom: 3 }} />
                  <Box className="form-outers">
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="aadhar_card"
                          name="aadhar_card"
                          label="Aadhar Card"
                          className="accessory_img"
                          type="file"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                          inputProps={{ accept: "image/*,.doc, .docx,.pdf" }}
                          autoComplete="images"
                          onChange={(e) =>
                            handleDocumentChange(e, "aadhar_card")
                          }
                        />
                        {formData.aadhar_card_type === "image" &&
                          formData.aadhar_card_url !== "" && (
                            <Grid item xs={12} className="imageDiv">
                              <div>
                                <img
                                  src={formData.aadhar_card_url}
                                  alt={`aadhar_card`}
                                  className="preview-img"
                                />
                              </div>
                            </Grid>
                          )}
                        {formData.aadhar_card_type === "pdf" &&
                          formData.aadhar_card_url !== "" && (
                            <Grid item xs={12} className="pdf-icon">
                              <PictureAsPdfIcon /> Aadhar Card
                            </Grid>
                          )}
                        {formData.aadhar_card_type === "word" &&
                          formData.aadhar_card_url !== "" && (
                            <Grid item xs={12} className="pdf-icon">
                              <DescriptionIcon /> Aadhar Card
                            </Grid>
                          )}
                        {formData.aadhar_card !== null && (
                          <Button
                            style={{ textAlign: "center" }}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                aadhar_card: null,
                                aadhar_card_url: "",
                                aadhar_card_type: "",
                                delete_img: [
                                  ...formData.delete_img,
                                  formData.aadhar_card,
                                ],
                              });
                              const thumbnailElement = document.getElementById(
                                "aadhar_card"
                              ) as HTMLInputElement | null;
                              if (thumbnailElement) {
                                thumbnailElement.value = "";
                              }
                            }}
                          >
                            Remove
                          </Button>
                        )}
                        <Error current_key="aadhar_card" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="pan_card"
                          name="pan_card"
                          label="Pan Card"
                          className="accessory_img"
                          type="file"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                          inputProps={{ accept: "image/*,.doc, .docx,.pdf" }}
                          autoComplete="images"
                          onChange={(e) => handleDocumentChange(e, "pan_card")}
                        />
                        {formData.pan_card_type === "image" &&
                          formData.pan_card_url !== "" && (
                            <Grid item xs={12} className="imageDiv">
                              <div>
                                <img
                                  src={formData.pan_card_url}
                                  alt={`pan_card`}
                                  className="preview-img"
                                />
                              </div>
                            </Grid>
                          )}
                        {formData.pan_card_type === "pdf" &&
                          formData.pan_card_url !== "" && (
                            <Grid item xs={12} className="pdf-icon">
                              <PictureAsPdfIcon /> Pan Card
                            </Grid>
                          )}
                        {formData.pan_card_type === "word" &&
                          formData.pan_card_url !== "" && (
                            <Grid item xs={12} className="pdf-icon">
                              <DescriptionIcon /> Pan Card
                            </Grid>
                          )}
                        {formData.pan_card !== null && (
                          <Button
                            style={{ textAlign: "center" }}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                pan_card: null,
                                pan_card_url: "",
                                pan_card_type: "",
                                delete_img: [
                                  ...formData.delete_img,
                                  formData.pan_card,
                                ],
                              });
                              const thumbnailElement = document.getElementById(
                                "pan_card"
                              ) as HTMLInputElement | null;
                              if (thumbnailElement) {
                                thumbnailElement.value = "";
                              }
                            }}
                          >
                            Remove
                          </Button>
                        )}
                        <Error current_key="pan_card" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="aadhar_card_number"
                          label="Aadhar Card *"
                          name="aadhar_card_number"
                          type="text"
                          size="small"
                          autoComplete="aadhar_card_number"
                          value={formData.aadhar_card_number}
                          onChange={handleChange}
                          error={!!formErrors.aadhar_card_number}
                          helperText={formErrors.aadhar_card_number}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="pan_card"
                          label="Pan Card *"
                          name="pan_card_number"
                          type="text"
                          size="small"
                          autoComplete="pan_card_number"
                          value={formData.pan_card_number}
                          onChange={handleChange}
                          error={!!formErrors.pan_card_number}
                          helperText={formErrors.pan_card_number}
                        />
                        <Error current_key="pan_card_number" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="bank_detail"
                          name="bank_detail"
                          label="Bank Detail"
                          className="accessory_img"
                          type="file"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                          inputProps={{ accept: "image/*,.doc, .docx,.pdf" }}
                          autoComplete="images"
                          onChange={(e) =>
                            handleDocumentChange(e, "bank_detail")
                          }
                        />
                        {formData.bank_detail_type === "image" &&
                          formData.bank_detail_url !== "" && (
                            <Grid item xs={12} className="imageDiv">
                              <div>
                                <img
                                  src={formData.bank_detail_url}
                                  alt={`bank_detail`}
                                  className="preview-img"
                                />
                              </div>
                            </Grid>
                          )}
                        {formData.bank_detail_type === "pdf" &&
                          formData.bank_detail_url !== "" && (
                            <Grid item xs={12} className="pdf-icon">
                              <PictureAsPdfIcon /> Bank Detail
                            </Grid>
                          )}
                        {formData.bank_detail_type === "word" &&
                          formData.bank_detail_url !== "" && (
                            <Grid item xs={12} className="pdf-icon">
                              <DescriptionIcon /> Bank Detail
                            </Grid>
                          )}
                        {formData.bank_detail !== null && (
                          <Button
                            style={{ textAlign: "center" }}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                bank_detail: null,
                                bank_detail_url: "",
                                bank_detail_type: "",
                                delete_img: [
                                  ...formData.delete_img,
                                  formData.bank_detail,
                                ],
                              });
                              const thumbnailElement = document.getElementById(
                                "bank_detail"
                              ) as HTMLInputElement | null;
                              if (thumbnailElement) {
                                thumbnailElement.value = "";
                              }
                            }}
                          >
                            Remove
                          </Button>
                        )}
                        <Error current_key="bank_detail" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          id="driving_license"
                          name="driving_license"
                          label="Driving License"
                          className="accessory_img"
                          type="file"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          size="small"
                          inputProps={{ accept: "image/*,.doc, .docx,.pdf" }}
                          autoComplete="images"
                          onChange={(e) =>
                            handleDocumentChange(e, "driving_license")
                          }
                        />
                        {formData.driving_license_type === "image" &&
                          formData.driving_license_url !== "" && (
                            <Grid item xs={12} className="imageDiv">
                              <div>
                                <img
                                  src={formData.driving_license_url}
                                  alt={`driving_license`}
                                  className="preview-img"
                                />
                              </div>
                            </Grid>
                          )}
                        {formData.driving_license_type === "pdf" &&
                          formData.driving_license_url !== "" && (
                            <Grid item xs={12} className="pdf-icon">
                              <PictureAsPdfIcon /> Driving License{" "}
                            </Grid>
                          )}
                        {formData.driving_license_type === "word" &&
                          formData.driving_license_url !== "" && (
                            <Grid item xs={12} className="pdf-icon">
                              <DescriptionIcon /> Driving License{" "}
                            </Grid>
                          )}
                        {formData.driving_license !== null && (
                          <Button
                            style={{ textAlign: "center" }}
                            onClick={() => {
                              setFormData({
                                ...formData,
                                driving_license: null,
                                driving_license_url: "",
                                driving_license_type: "",
                                delete_img: [
                                  ...formData.delete_img,
                                  formData.driving_license,
                                ],
                              });
                              const thumbnailElement = document.getElementById(
                                "driving_license"
                              ) as HTMLInputElement | null;
                              if (thumbnailElement) {
                                thumbnailElement.value = "";
                              }
                            }}
                          >
                            Remove
                          </Button>
                        )}
                        <Error current_key="driving_license" />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
          <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="save-btn"
              style={{ marginRight: "10px" }}
              onClick={handleExternalSubmit}
              disabled={Object.values(formErrors).some((error) => !!error)}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="close-btn"
              onClick={() => handleModel(false)}
            >
              Cancel
            </Button>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default EmployeeAddEditModal;
